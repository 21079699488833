import React, { useState, useEffect } from 'react';
import './App.css';
import Swal from 'sweetalert2';
import env from "react-dotenv";


function App() {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadTable();
  }, []);
  const local_host_link = process.env.https://test.dev-avaxialabs.com/;

  const loadTable = () => {
    fetch(local_host_link + "notes")
      .then((response) => response.json())
      .then((data) => {
        setNotes(data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const showCreateBox = () => {
    Swal.fire({
      title: 'Add a note',
      html: (
        '<input id="note_title" class="swal2-input" placeholder="Note title">' +
        '<input id="note_text" class="swal2-input" placeholder="Note text">'
      ),
      focusConfirm: false,
      preConfirm: () => {
        createNote();
      },
    });
  };

  const createNote = () => {
    const note_title = document.getElementById('note_title').value;
    const note_text = document.getElementById('note_text').value;

    fetch(local_host_link + 'note', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({
        note: {
          note_title: note_title,
          note_text: note_text,
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        Swal.fire(data.message);
        loadTable();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const showEditBox = (id) => {
    fetch(local_host_link + 'get_note/' + id)
      .then((response) => response.json())
      .then((data) => {
        const note = data.data;
        Swal.fire({
          title: 'Edit Note',
          html: (
            '<input id="id" type="hidden" value=' + note[0].id + '>' +
            '<div><label for="html"><b>Note Title</b></label>' +
            '<input id="note_title" class="swal2-input" placeholder="Note title" value="' + note[0].note_title + '"></div>' +
            '<div><label for="html"><b>Note Text</b></label>' +
            '<input id="note_text" class="swal2-input" placeholder="Note text" value="' + note[0].note_text + '"></div>'
          ),
          focusConfirm: false,
          preConfirm: () => {
            editNote();
          },
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const editNote = () => {
    const id = document.getElementById('id').value;
    const note_title = document.getElementById('note_title').value;
    const note_text = document.getElementById('note_text').value;

    fetch(local_host_link + 'note/' + id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({
        note: {
          note_title: note_title,
          note_text: note_text,
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        Swal.fire(data.message);
        loadTable();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteNote = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(local_host_link + 'note/' + id, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
          },
          body: JSON.stringify({
            id: id,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            Swal.fire('Deleted!', data.message, 'success');
            loadTable();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  };

  return (
    <div className="App">
      <nav className="navbar navbar-dark bg-mynav">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            Avaxia Template 
          </a>
        </div>
      </nav>

      <div className="container">
        <div className="d-flex bd-highlight mb-3">
          <div className="me-auto p-2 bd-highlight">
            <h2>Notes</h2>
          </div>
          <div className="p-2 bd-highlight">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={showCreateBox}
            >
              Create note
            </button>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Note title</th>
                <th scope="col">Note text</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody id="mytable">
              {loading ? (
                <tr>
                  <th scope="row" colSpan="4">Loading...</th>
                </tr>
              ) : (
                notes.map((note) => (
                  <tr key={note.id}>
                    <td>{note.id}</td>
                    <td>{note.note_title}</td>
                    <td>{note.note_text}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={() => showEditBox(note.id)}
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={() => deleteNote(note.id)}
                      >
                        Del
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default App;
